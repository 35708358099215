const local ={
    NAME: 'Local',
    API_BASE_URL:'http://localhost:5000',
    API_URL: 'http://localhost:5000/api',
    SOCKET_URL:'ws://localhost:8081/',
    CLIENT_ID: "76da7585-9f56-48b7-9970-ce6815b22bb5",
    AUTHORITY: "209a71f6-c9bb-48f1-a23d-a26d275b75e2",
    API_SCOPES: "8e314aca-9ab7-47a7-9656-6c623bfa48fd"
}

export default local;
