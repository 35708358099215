import React, { useState, useEffect } from 'react';
import './Login.css';
import axios from 'axios'; 
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../config';
import { SsoRedirect } from './ssoRedirect';
import { API_BASE_URL } from '../utils/Constants';

const BASE_URL = config.ssoRedirect;

const Login = (props) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientIdValue = queryParams.get('client_id');

    let source; // Declare 'source' outside to be accessible to both 'useEffect' and 'handleSubmit'

    useEffect(() => {
        return () => {
            // Cleanup function to cancel any pending API calls when the component unmounts
            if (source) {
                source.cancel('Component unmounted');
            }
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        source = axios.CancelToken.source(); // Assign 'source' here

        try {
            const url = `${API_BASE_URL}/login`;
            const response = await axios.post(
                `${url}?client_id=${clientIdValue}`,
                {},  
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source.token // Attach the cancel token to the request
                }
            );
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('clientId', response.data.client_id);
            navigate("/mdc/auth/media/");
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                setError('An unexpected error occurred');
                console.error(error);
            }
        } finally {
            if (!axios.isCancel(error)) { 
                setLoading(false); 
            }
        }
    };

    const loginForm = () => (
        <div className="logincontainer">
            <div className="loginform">
                <h1>Login</h1>
                <form onSubmit={handleSubmit}>
                    <button className="loginbtn" type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
                {error && <p className="error">{error}</p>}
            </div>
        </div>
    );

    // Conditional Rendering Logic
    if (clientIdValue) {
        return <div>{loginForm()}</div>;
    }

    if (config.mode === 'sso') {
        return <div>{SsoRedirect(props)}</div>;
    }

    return null;
};

export function logout() {
    window.location.href = `${BASE_URL}/auth/json/sessions/?_action=logout`;
}

export default Login;
